import Vue from "vue";

// 全局引入lodash，参考文档：https://www.lodashjs.com/
import _ from "lodash";
Vue.prototype._ = _;

// 完整引入elementUI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import { myMessage } from "./assets/plugins/resetMessage";
Vue.use(ElementUI);

import App from "./App.vue";
import store from "./store";
import commons from "@/js/commom";
Vue.prototype.$commons = commons;

import router from "./router";
Vue.use(router);

import VueClipboard from "vue-clipboard2"; //复制
Vue.use(VueClipboard); //复制

// 视频
import VideoPlayer from "vue-video-player";
import "vue-video-player/src/custom-theme.css";
import "video.js/dist/video-js.css";
import "videojs-contrib-hls";
Vue.use(VideoPlayer); //播放控件

// import Paragraph from "vue-paragraph"; //文本溢出提示
// Vue.use(Paragraph);

// 引入公共js文件
import "@/assets";
// 引入公共组件js文件
import "@/components";
import "@/css/list.css"; //公共列表样式
import storage from "@/api/storage";

Vue.prototype.$message = myMessage;
Vue.prototype.$storage = storage;

router.beforeEach((to, from, next) => {
  let title = to?.meta?.title;
  document.title = "培训" + (title ? "-" + title : ""); // 设置页面标题
  next();
});

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
