export default {
  namespaced: true,
  local: true,
  state: {
    access_token: "",
    userId: "", // 用户id
    compId: "", // 企业id
    compName: "", // 公司简称
    userName: "",
    userFace: "", //用户（公司）头像
    userSign: "", // 用户身份标识 company企业 staff员工
    systemSign: "", // 1 hrm（甲方） 、2 hro（乙方） systemSign 为空表示没有外包业务
    staffType: "", // 1 合同 、2 外包
    module: "", // 招聘12  RPO13
    compAdmin: 0, //0不是管理员，1普通管理员，2超级管理员（注意此处描述的管理员并非权限中设置的管理员，与isAdmin和isAdminObj无任何关系）
    user: {
      sysType: "",
      userId: "", //登录者id
      userLoginCode: "", //
      userName: "", //登录者姓名
      userType: "", //登录者类型：2-公司；其他是员工
      vipId: "", //登录者公司id
    },
    userInfo: {},
  },
  mutations: {
    login(state, info) {
      console.log(info, "========info=============");
      if (info.access_token) state.access_token = info.access_token;
      if (info.userId) state.userId = info.userId;
      if (info.vipId) state.compId = info.vipId;
      if (info.compName) state.compName = info.compName;
      if (info.userName) state.userName = info.userName;
      if (info.userType)
        state.userSign = info.userType == 2 ? "company" : "staff";
      // if (info.module) state.systemSign = info.module;
      if (info.sysType) state.systemSign = info.sysType;
      if (info.staffType) state.staffType = info.staffType;
      if (info.module) state.module = info.module;
      if (info.compAdmin || info.compAdmin == 0) {
        state.compAdmin = info.compAdmin;
      }
      if (info.user) state.user = info.user;
      if (info.userInfo) state.userInfo = info.userInfo;
    },
    logout(state) {
      console.log(state, "==state");
      state.access_token = "";
      state.compId = "";
      state.compName = "";
      state.userId = "";
      state.userName = "";
      state.userFace = "";
      state.userType = "";
      state.systemSign = "";
      state.staffType = "";
      state.module = "";
      state.compAdmin = 0;
      state.user = {};
      state.userInfo = {};
    },
  },
};
