<!--
自定义iconfont图标+文字样式
props:{
    name: String，图标样式名称,
    size: String，图标大小（不含文本）,如：15px
    reverse: Boolean,是否颠倒顺序，默认false
    direction: String, 显示方向：row(水平方向)/column（垂直方向）
    gap: String, 图文间距
    text: String, 显示文本
}
-->
<template>
  <div class="g-icon" :style="{ flexDirection, gap }">
    <div
      :class="['iconfont', name, { closed: closed }]"
      :style="{ fontSize: size }"
      @click="$emit('click', $event)"
    ></div>
    <div class="label" :style="{ color: textColor }">
      <slot>{{ text }}</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "g-icon",
  props: {
    name: String, //图标名称
    size: String, //图标大小（不含文本）
    color: String, //图标颜色（不含文本），默认继承
    reverse: { type: Boolean, default: false }, //颠倒顺序
    direction: { type: String, default: "row" }, //方向：row(水平方向)/column（垂直方向）
    gap: String, //间距
    text: [String, Number], //显示文本
    textColor: String, //文本颜色，默认继承
    closed: { type: Boolean, default: false }, //关闭图标（图标加斜线）
    closedColor: { type: Array, default: () => ["#ffffff", "#e4e4e4"] }, //关闭图标（图标加斜线）,第一个值代表图标所处环境背景色，第二个值代表斜线颜色（同时要更改图标颜色）
  },
  computed: {
    flexDirection() {
      let str = this.direction + (this.reverse ? "-reverse" : "");
      return str;
    },
  },
};
</script>

<style lang="less" scoped>
@color: v-bind("color");
@closedColor1: v-bind("closedColor[0]");
@closedColor2: v-bind("closedColor[1]");
.g-icon {
  display: flex;
  align-items: center;
  line-height: 1;
  .iconfont {
    color: inherit;
    line-height: inherit;
    position: relative;
    color: @color;
    &.closed {
      color: @closedColor2;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1.5px;
        height: 115%;
        background-color: @closedColor2;
        border-left: 2px solid @closedColor1;
        border-right: 2px solid @closedColor1;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
  .label {
    font-size: inherit;
    line-height: inherit;
  }
}
</style>
