<template>
  <div id="app">
    <!-- <keep-alive> -->
    <router-view />
    <!-- </keep-alive> -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
#app {
  width: 100%;
}
</style>
