export default {
  parent: "Member",
  routers: [
    // 设置
    {
      path: "setting",
      name: "Setting",
      component: () => import("@/views/setting/SettingView.vue"),
    },
  ],
};
