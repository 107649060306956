// 个人中心
export default {
  routers: [

    // // 个人会员中心登录页面
    // {
    //   path: "/personal/login",
    //   name: "p_login",
    //   component: () => import("@/views/personal/loginView.vue"),
    // },

    // 首页
    {
      path: "/personal",
      name: "p_home",
      component: () => import("@/views/personal/courseView.vue"),
      meta: { title: "个人中心" },
    },
    // 我的课程
    {
      path: "/personal/course",
      name: "p_course",
      component: () => import("@/views/personal/courseView.vue"),
      meta: { title: "个人中心-我的课程" },
    },
    // 我的订单
    {
      path: "/personal/order",
      name: "p_order",
      component: () => import("@/views/personal/orderView.vue"),
      meta: { title: "个人中心-我的订单" },
    },
    // 我的收藏
    {
      path: "/personal/collect",
      name: "p_collect",
      component: () => import("@/views/personal/collectView.vue"),
      meta: { title: "个人中心-我的收藏" },
    },
    // 我的笔记
    {
      path: "/personal/note",
      name: "p_note",
      component: () => import("@/views/personal/noteView.vue"),
      meta: { title: "个人中心-我的笔记" },
    },
    // 个人信息
    {
      path: "/personal/info",
      name: "p_info",
      component: () => import("@/views/personal/infoView.vue"),
      meta: { title: "个人中心-个人资料" },
    },
    // 账号与安全
    {
      path: "/personal/safe",
      name: "p_safe",
      component: () => import("@/views/personal/safeView.vue"),
      meta: { title: "个人中心-账号与安全" },
    },

  ],
};
