export default {
  parent: "live",
  routers: [
    // 直播-我学习的
    {
      path: "study",
      name: "zb-study",
      component: () => import("@/views/train/live/indexView.vue"),
      meta: { title: "直播-我学习的" },
    },
    // 直播-我购买的
    {
      path: "buy",
      name: "zb-buy",
      component: () => import("@/views/train/live/indexView.vue"),
      meta: { title: "直播-我购买的" },
    },
    // 直播-我发布的
    {
      path: "creat",
      name: "zb-creat",
      component: () => import("@/views/train/live/indexView.vue"),
      meta: { title: "直播-我发布的" },
    },
    // 直播-全部
    {
      path: "all",
      name: "zb-all",
      component: () => import("@/views/train/live/indexView.vue"),
      meta: { title: "直播-全部" },
    },
  ],
};
