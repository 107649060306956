import useApi from "@/api/train/exam";
import { studyApi } from "@/assets/plugins/axios";

const state = {
  paperInfo: {}, //问卷信息
  paperQuesInfo: [], //问卷试题信息
};

const mutations = {
  SET_PAPERINFO: (state, data) => {
    state.paperInfo = data;
  },
  SET_PAPERQUESINFO: (state, data) => {
    state.paperQuesInfo = data;
  },
};

const actions = {
  // 更新问卷信息
  getPaperInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      useApi(studyApi)
        .selectPaperByPaperIdNose(data)
        .then((res) => {
          if (res.code == "200") {
            resolve(res.data.result);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 缓存问卷信息
  async setPaperInfo({ commit }, data) {
    commit("SET_PAPERINFO", data);
  },
  // 更新问卷试题信息
  updatePaperQuesInfo({ commit }, data) {
    commit("SET_PAPERQUESINFO", data);
  },
};

export default {
  //namespaced: true,
  state,
  mutations,
  actions,
};
