export default function (axios) {
  return {
    // 登录接口
    login(params) {
      return axios.post("/anon/generateToken", params);
    },
    // 退出登录接口
    logout() {
      return axios.get("/invalidToken");
    },
    // 获取可切换的身份列表
    getUserList() {
      return axios.get("/userCtl/changeUserList");
    },
    // 切换用户身份
    changeUser(params) {
      return axios.get("/userCtl/changeUser", { params });
    },
    // 获取用户信息
    getUserInfo() {
      return axios.get("/userCtl/selectUserInfoByToken");
    },
    // 获取企业会员信息
    getCompanyInfo(params) {
      return axios.get("/compMemberCtl/selectCompMemberById", { params });
    },
    // 获取验证码
    getCode(userLoginCode, type) {
      return axios.get("/anon/verifyCode", {
        params: { userLoginCode, verifyType: type ? type : 2 },
      });
    },
    // 校验token是否过期
    getCheckToken() {
      return axios.get("/userCtl/checkTokenNose");
    },

    //保存渠道合作数据
    addApplyAgentNose(params) {
      return axios.post("/applyAgent/addApplyAgentNose", params);
    },

    compNameRepeat(params) {
      return axios.post("/anon/applyTrial/selectCompNameExist", {}, { params }); //查找公司名是否重复
    },
    //保存申请试用的信息
    addApplyTryNose(params) {
      // return axios.post("/applyTry/addApplyTryNose", params);
      return axios.post("/anon/applyTrial/submitApplication", params); //点击提交申请按钮
    },
    // 获取菜单栏
    getMenu(compId, userId) {
      return axios.get("/cAccountAuthCtl/selectAuthByUserId", {
        params: { compId, userId },
      });
    },
    // 获取常用菜单栏
    getDefaultMenu(params) {
      return axios.post("/cAccountAuthCtl/selectDefaultMenu", params);
    },
    // 常用菜单栏变更
    updateMenu(params) {
      return axios.post("/cAccountAuthCtl/updateDefaultAuth", params);
    },

    // 清空员工权限
    emptyStaffAuth(params) {
      return axios.post("/cPowerSetCtl/emptyPower", null, {
        params,
      });
    },

    // 获取员工权限
    getStaffAuth(compId, childAccountId) {
      return axios.get("/cPowerSetCtl/selectPower", {
        params: { compId, childAccountId },
      });
    },
    // 修改员工权限
    changeStaffAuth(params) {
      return axios.post("/cPowerSetCtl/updatePower", params);
    },

    // /userCtl/getMatcheasyStaffId   获取好招的员工id
    getMatcheasyStaffId(params) {
      return axios.get("/userCtl/getMatcheasyStaffId", {
        params,
      });
    },

    // 获取下拉选项
    getPullList(type) {
      return axios.get("/parameter/getDictNose", {
        params: { parameterType: type },
      });
    },

    // 获取省市区选项
    selectProvinceAndCity(nose) {
      let url = "/companyParamCtl/selectProvinceAndCity";
      url = url + (nose ? "Nose" : "");
      return axios.get(url);
    },
    // 	虚拟公司激活  /compMemberCtl/activateCompanyNose
    activateCompany(data) {
      return axios.post("/compMemberCtl/activateCompanyNose", data);
    },

    getXcxUrl(params) {
      return axios.post("/anon/applet/getAppletConnection", params); //生成小程序路径或二维码
    },
    // /查询单个项目的已选成员和管理员
    getCustomerMember(compId, customerId) {
      return axios.get("/customer/selectCustomerMember", {
        params: { compId, customerId },
      });
    },
    // 添加客户的成员
    addCustomerMember(params) {
      return axios.post("/customer/addCustomerMember", params);
    },
    //删除客户成员
    deleteCustomerMember(params) {
      return axios.post("/customer/deleteCustomerMember", params);
    },
    //查询单个项目的已选成员和管理员
    getSelectSupplierMember(compId, customerId) {
      return axios.get("/outsource/selectSupplierMember", {
        params: { compId, customerId },
      });
    },
    // 添加供应商的成员
    addSupplierMember(params) {
      return axios.post("/outsource/addSupplierMember", params);
    },
    //删除客户成员
    deleteSupplierMember(params) {
      return axios.post("/outsource/deleteSupplierMember", params);
    },

    // /userCtl/getCompanyTxSignStatus   获取用户的企业腾讯电子签开通状态
    getCompanyTxSignStatus() {
      return axios.get("/userCtl/getCompanyTxSignStatus");
    },

    // 发票信息***************************************
    // 发票-增
    insertReceiptInfo(params) {
      return axios.post("/receiptInfo/insertReceiptInfo", params);
    },
    // 发票-删+设默认
    updateReceiptStatus(params) {
      return axios.post("/receiptInfo/updateReceiptStatus", {}, { params });
    },
    // 发票-改
    updateReceiptInfo(params) {
      return axios.post("/receiptInfo/updateReceiptInfo", params);
    },
    // 发票-查
    selectReceiptInfo(params) {
      return axios.post("/receiptInfo/selectReceiptInfo", params);
    },

    // /userSig/getUserSig 获取签名
    getStaffcloudIMUserSig(params) {
      return axios.get("/userSig/getUserSig", { params });
    },
  };
}
