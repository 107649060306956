export default {
  parent: "student",
  routers: [
    // 学员-内部的
    {
      path: "in",
      name: "xy-in",
      component: () => import("@/views/train/student/indexView.vue"),
      meta: { title: "学员-内部的" },
    },
    // 学员-外部的
    {
      path: "out",
      name: "xy-out",
      component: () => import("@/views/train/student/indexView.vue"),
      meta: { title: "学员-外部的" },
    },
  ],
};
