export default {
  routers: [
    // 首页
    {
      path: "/home",
      name: "tocHome",
      component: () => import("@/views/toc/indexView.vue"),
    },
    // 课程-列表
    {
      path: "/course",
      name: "tocCourse",
      component: () => import("@/views/toc/courseView.vue"),
    },
    // 课程详情
    // {
    //   path: "/detail",
    //   name: "tocDetail",
    //   component: () => import("@/views/toc/mallView.vue"),
    // },
    // 课程播放
    {
      path: "/play",
      name: "tocPlay",
      component: () => import("@/views/toc/playView.vue"),
    },
    // 直播-列表
    {
      path: "/live",
      name: "tocLive",
      component: () => import("@/views/toc/liveView.vue"),
    },
    // 培训班-列表
    {
      path: "/class",
      name: "tocClass",
      component: () => import("@/views/toc/classView.vue"),
    },
    // 培训班-详情
    {
      path: "/class/detail",
      name: "tocClassDetail",
      component: () => import("@/views/toc/classDetail.vue"),
    },

    // 购买流程-确定订单
    {
      path: "/order/confirm/:type/:id",
      name: "tocOrderConfirm",
      component: () => import("@/views/toc/orderConfirm.vue"),
    },
    // 购买流程-订单支付
    {
      path: "/order/pay/:type/:id",
      name: "tocOrderPay",
      component: () => import("@/views/toc/orderPay.vue"),
    },


  ],
};
